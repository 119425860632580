import styled from "styled-components";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import MainLayout from "../common/layouts/main";
import ListKeyword from "../features/keyword/listKeyword";
// import InputKeyword from "../features/keyword/inputKeyword";
import keywordProviders from "../features/keyword/provider/keywordProvider";
import ChartProvider from "../features/dashboard/provider/chartProvider";

const StyleMainkeywordPage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
  height: calc(100dvh - ${(props) => props.theme.navbarHeight} - 30px);
  gap: 24px;

  > div {
    padding: 16px;
    border-radius: 10px;
    height: fit-content;
  }
`;

const MainKeyword = () => {
  const keywordProvider = keywordProviders();
  const chartProvider = ChartProvider();
  // const [formKeyword] = Form.useForm();
  const [data, setData] = useState([]);
  // const [isEdit, setIsEdit] = useState(false);
  // const [editValue, setEditValue] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idDelete, setIdDelete] = useState(0);

  const getData = async () => {
    const res = await keywordProvider.getListKeyword();
    if (res) {
      setData(res);
    }
  };

  const fetchcheckPermission = async () => {
    await chartProvider.checkPermission();
  };

  // const handleAdd = async (value) => {
  //   const payload = {
  //     name: value,
  //   };
  //   const res = await keywordProvider.addListKeyword(payload);
  //   if (res === 200) getData();
  // };

  // SET KEYWORD TO INPUT FIELD
  // const setEditInput = (item) => {
  // setIsEdit(true);
  // setEditValue(item);
  //   formKeyword.setFieldValue("mainKeyword", item.name);
  // };

  // FN CALL EDIT
  // const handleEdit = async (value) => {
  //   const payload = {
  //     id: editValue.id,
  //     name: value,
  //   };
  //   const res = await keywordProvider.editListKeyword(payload);
  //   if (res === 200) {
  //     getData();
  //     setIsEdit(false);
  //     setEditValue({});
  //     formKeyword.setFieldValue("mainKeyword", "");
  //   }
  // };

  const openModalConfirmDelete = (id) => {
    setIsOpenModal(true);
    setIdDelete(id);
  };

  const handleDelete = async () => {
    const payload = {
      id: idDelete,
    };
    const res = await keywordProvider.deleteListKeyword(payload);
    if (res === 200) {
      getData();
      setIsOpenModal(false);
      setIdDelete(0);
    }
  };

  const handleCancelDelete = () => {
    setIsOpenModal(false);
    setIdDelete(0);
  };

  useEffect(() => {
    getData();
    fetchcheckPermission();
    setIdDelete(0);
    setIsOpenModal(false);
  }, []);

  return (
    <MainLayout>
      <StyleMainkeywordPage>
        <ListKeyword
          data={data}
          openModalConfirmDelete={openModalConfirmDelete}
          getData={getData}
        />
      </StyleMainkeywordPage>
      <Modal
        title="Do you want to delete this keyword"
        open={isOpenModal}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
      />
    </MainLayout>
  );
};

export default MainKeyword;
