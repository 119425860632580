import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const keywordProvider = () => {
  const { axios } = useAxios();

  const getListKeyword = async () => {
    try {
      const url = `${host}/campaigns`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const getListAccountCampaign = async () => {
    try {
      const url = `${host}/account-campaign`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const addListKeyword = async (payload) => {
    const { body } = payload;
    try {
      const url = `${host}/campaigns`;
      const res = await axios.post(url, body);
      return res.status;
    } catch (error) {
      return error;
    }
  };

  const editListKeyword = async (payload) => {
    const { id, body } = payload;
    try {
      const url = `${host}/campaigns/${id}`;
      const res = await axios.patch(url, body);
      return res.status;
    } catch (error) {
      return error;
    }
  };

  const deleteListKeyword = async (payload) => {
    const { id } = payload;
    try {
      const url = `${host}/campaigns/${id}`;
      const res = await axios.delete(url);
      return res.status;
    } catch (error) {
      return error;
    }
  };

  return {
    getListKeyword,
    getListAccountCampaign,
    addListKeyword,
    editListKeyword,
    deleteListKeyword,
  };
};

export default keywordProvider;
